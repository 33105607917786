import { BaseAutocomplete } from "./base_autocomplete";
import * as validator from 'email-validator';
import {displayErrors} from "../shared/common";
import $ from 'jquery';

export class MultiAutocomplete extends BaseAutocomplete {
    SELECTED = {};

    constructor(aid, tbl) {
        super(aid, tbl);
    }

    setup(uid) {
        super.setup(uid);
        this.setupMultiDelete(uid);
    }

    selectAuto(e, ui, uid) {
        e.preventDefault();

        const container = app.AUTOCOMPLETE_CORE.AUTOCOMPLETE_CONTAINERS[uid];
        this.SELECTED[uid] = container.container.find(`.autocomplete-multi`);

        $(e.target).val('');

        const f = container.input.data('fieldname') ?? this.AUTOCOMPLETE_ID;
        const exists = this.SELECTED[uid].find(`input[data-val="${ui.item.value}"]`);
        if(exists && exists.length > 0 ) {
            alert('This '+ app.TBL[this.AUTOCOMPLETE_TBL].i +' has already been added');
            return false;
        }

        const multiHtml = `
            <span class="autocomplete-multi">
                <input type="hidden" name="${container.input.data('fieldname') ?? ''}[${ui.item.value}]">
                ${ui.item.label}
                <i class="fa fa-times btn-autocomplete-clear-multi-${this.AUTOCOMPLETE_TBL}" title="Remove selection" data="${f}_${ui.item.value}"></i>
            </span>
        `;

        // add html
        this.CONTAINER.input_hidden.append(multiHtml);
        this.CONTAINER.input.removeClass('required');

        // re-cache
        this.setupMultiDelete(uid);
    }

    onKeyDown(e, uid) {
        if(e.keyCode === 13) {
            // Enter key - add whatever's in the box as if it were a valid email
            if (validator.validate(e.target.value)) {
                this.selectAuto(e, {
                    item: {
                        value: e.target.value,
                        label: e.target.value,
                    }
                }, uid);
                $(e.target).removeClass('error');
            } else {
                e.preventDefault()
                $(e.target).addClass('error');
            }
        } else {
            $(e.target).removeClass('error');
        }
    }

    setupMultiDelete(uid) {
        this.SELECTED[uid] = app.AUTOCOMPLETE_CORE.AUTOCOMPLETE_CONTAINERS[uid].container.find(`.btn-autocomplete-clear-multi-${this.AUTOCOMPLETE_TBL}`);

        this.SELECTED[uid].unbind('click').on('click', (e) => {
            if( app.OPTIONS.form && confirm("Remove?") === false ) {
                return;
            }

            $(e.currentTarget).closest('span.autocomplete-multi').remove();
            this.SELECTED[uid] = app.AUTOCOMPLETE_CORE.AUTOCOMPLETE_CONTAINERS[uid].container.find(`.btn-autocomplete-clear-multi-${this.AUTOCOMPLETE_TBL}`);


            this.checkMultiHasValues(uid);
        });

        // check initial state
        if( app.FORM.action === 'edit' && (app.URI[1] !== 'msg' || app.DOM.form.find('#type').val() === 'usr') ) {
            this.checkMultiHasValues(uid);
        }
    }

    checkMultiHasValues(uid) {
        const container = app.AUTOCOMPLETE_CORE.AUTOCOMPLETE_CONTAINERS[uid];
        const $inputs = container.container.find(`input:hidden[name*="${container.input.data('fieldname') ?? this.AUTOCOMPLETE_ID}["]`);

        if( $inputs.length ) {
            container.input.removeClass('required');
        } else {
            container.input.addClass('required');
        }
    }
}