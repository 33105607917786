import $ from 'jquery';

export class BaseAutocomplete {
    AUTOCOMPLETE_ID;
    AUTOCOMPLETE_TBL;
    CONTAINER;

    constructor(aid, tbl) {
        if(!aid) {
            console.warn('No autocomplete ID provided.');
            return;
        }

        this.AUTOCOMPLETE_ID = aid;
        this.AUTOCOMPLETE_TBL = tbl;
        window.dispatchEvent(new CustomEvent('RegisterAutocomplete', {
            detail: { id: this.AUTOCOMPLETE_ID, instance: this }
        }));
    }

    setup(uid) {
        if(!app.AUTOCOMPLETE_CORE.AUTOCOMPLETE_CONTAINERS[uid]) {
            console.warn('Unable to setup autocomplete');
            return;
        }
        this.CONTAINER = app.AUTOCOMPLETE_CORE.AUTOCOMPLETE_CONTAINERS[uid];
    }

    getAjaxData(autocompleteData) {
        let data = {
            tbl_ref: autocompleteData.tbl_ref ? autocompleteData.tbl_ref : app.URI[1],
            type:  (app.OPTIONS.form ) ? 'form' : app.URI[1]
        };

        if( app.OPTIONS.dashboard ) {
            data.tbl_ref = app.OPTIONS.dashboard;
            data.type = 'dashboard';
        } else if( app.URI[1] === 'list' || app.URI[1] === 'planner' ) {
            data.tbl_ref = app.URI[2];
        }

        // references purposes
        if( $.isNumeric(app.URI[2]) ){
            data.id = app.URI[2];
        }

        if( app.URI[1] === autocompleteData.tbl ) {
            if( $.isNumeric(app.URI[2]) ){
                data.exclude_id = app.URI[2];
            }
        } else if($.isNumeric(app.URI[2])) {
            data.tbl_id = app.URI[2];
        }

        return data;
    }

    selectAuto(e, ui, uid) {
        if(e) {
            e.preventDefault();
        }

        // update dom
        this.CONTAINER.input_hidden.val(ui.item.value).trigger('change');
        this.CONTAINER.input.val(ui.item.label);

        // change buttons
        app.AUTOCOMPLETE_CORE.showClear();

        if( app.LIST && app.DOM.filter_submit && !this.CONTAINER.input.data('disableAutoSubmit') ) {
            app.DOM.filter_submit.click();
        }

        if(app.DASHBOARD_CORE && this.CONTAINER.input.data('dashboard') === true) {
            app.DASHBOARD_CORE.updateFilters();
        }

        window.dispatchEvent(new CustomEvent('AutoCompleteSelect', {
            detail: {
                id: this.AUTOCOMPLETE_ID,
                tbl: this.AUTOCOMPLETE_TBL,
                data: ui.item,
            }
        }));
    }

    autocompleteOptions(autocompleteData, uid) {
        return {
            select: (e, ui) => this.selectAuto(e, ui, uid),
            focus: (e) => e.preventDefault(),
        };
    }

    onFocus(e, uid) {}
    onBlur(e, uid) {
        if(!app.AUTOCOMPLETE_CORE.AUTOCOMPLETE_CACHE[this.AUTOCOMPLETE_ID].data) {
            return;
        }
        const hasVal = app.AUTOCOMPLETE_CORE.AUTOCOMPLETE_CACHE[this.AUTOCOMPLETE_ID].data.filter((opt) => this.CONTAINER.input.val() === opt.label);
        if(this.CONTAINER && (this.CONTAINER.input_hidden.val() === '' || hasVal.length === 0)) {
            this.CONTAINER.input.val('');
            this.CONTAINER.input_hidden.val('');
            app.AUTOCOMPLETE_CORE.showSearch(uid);
        }
    }

    onKeyup(e, uid) {
        if(e.currentTarget.value === '') {
            app.AUTOCOMPLETE_CORE.showSearch(uid);
            this.CONTAINER.input_hidden.val('').trigger('change');
        } else {
            app.AUTOCOMPLETE_CORE.showClear(uid);
        }
    }
    onKeyDown(e, uid) {}

    clearSelection(e, uid) {
        const el = $(e.currentTarget);
        if(el.hasClass('disabled') || (app.OPTIONS.form && confirm("Remove?") === false)) {
            return;
        }
        this.CONTAINER.input.val('').trigger('change');
        this.CONTAINER.input_hidden.val('').focus();
        app.AUTOCOMPLETE_CORE.showSearch(uid);

        if(app.URI[1] === 'list') {
            app.DOM.filter_submit.click();
        }

        if(app.DASHBOARD_CORE && this.CONTAINER.input.data('dashboard') === true) {
            app.DASHBOARD_CORE.updateFilters();
        }

        window.dispatchEvent(new CustomEvent('AutoCompleteClear', {
            detail: {
                id: this.AUTOCOMPLETE_ID,
                tbl: this.AUTOCOMPLETE_TBL,
            }
        }));
    }
}